import { Box, Chip, Grid, Link, Typography } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import * as XLSX from "xlsx";
import UploadFileRoundedIcon from "@mui/icons-material/UploadFileRounded";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DataTable from "examples/Tables/DataTable";
import page_Routes from "page_routes";
import React, { useEffect, useState } from "react";
import { getPreApprovedStock } from "../../../redux/slices/marketing";
import { useDispatch, useSelector } from "react-redux";
import HomeIcon from "@mui/icons-material/Home";
import CustomSeparator from "examples/CustomSeprator/customSeprator";
import axios from "axios";

function DashboardMarketing() {
  const dispatch = useDispatch(); // Redux dispatch function
  const [category, setCategory] = useState("all");
  const [sales_order_last_updated, setSalesOrderLastUpdated] = useState(""); // State to store demand last updated
  const [sales_invoice_last_updated, setSalesInvoiceLastUpdated] = useState(""); // State to store invoice last updated
  console.log("api", category);
  const token = localStorage.getItem("token");
  useEffect(() => {
    dispatch(getPreApprovedStock(category)); // Call getContainStore1 action
    const getDate = async () => {
      try {
        const res = await axios.get("https://staging-2.trigeminal.ai/date", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("API response:", res.data);
        setSalesOrderLastUpdated(res.data?.sales_order_last_updated.date);
        setSalesInvoiceLastUpdated(res.data?.sales_invoice_last_updated.date);
      } catch (error) {
        console.error("Error fetching date:", error);
      }
    };

    getDate();
  }, [category]);
  const { marketing } = useSelector((state) => state.marketing);
  console.log("marketing", marketing);
  const columnDefs = [
    {
      headerName: "",
      children: [
        { headerName: "Sl No.", field: "sl_no", width: "100px", pinned: "left" },
        {
          headerName: "Product Code",
          filter: true,
          field: "product_code",
          width: "150px",
          pinned: "left",
        },
        {
          headerName: "Product",
          filter: true,
          field: "product_name",
          width: "210px",
          pinned: "left",
        },
        { headerName: "Unit", filter: true, field: "unit", width: "80px", pinned: "left" },
      ],
    },
    {
      headerName: "DLR",
      children: [
        { headerName: "DLR Stock", filter: true, field: "DLR_stock", width: "100px" },
        {
          headerName: "MTS Pending",
          filter: true,
          field: "DLR_Pending_for_MTS_Approval",
          width: "120px",
        },
        { headerName: "Stock - MTS", filter: true, field: "DLR_Total", width: "80px" },
      ],
    },
    {
      headerName: "Govt",
      children: [
        { headerName: "Govt Stock", filter: true, field: "Govt_stock", width: "130px" },
        {
          headerName: "MTS Pending",
          filter: true,
          field: "Govt_Pending_for_MTS_Approval",
          width: "120px",
        },
        { headerName: "Stock - MTS", filter: true, field: "Govt_Total", width: "80px" },
      ],
    },
    {
      headerName: "OTGT",
      children: [
        { headerName: "OTGT Stock", filter: true, field: "OTGT_stock", width: "130px" },
        {
          headerName: "MTS Pending",
          filter: true,
          field: "OTGT_Pending_for_MTS_Approval",
          width: "120px",
        },
        { headerName: "Stock - MTS", filter: true, field: "OTGT_Total", width: "100px" },
      ],
    },
    // {
    //   headerName: "GP",
    //   children: [
    //     { headerName: "GP Stock", field: "GP_stock", width: "130px" },
    //     { headerName: "MTS Pending", field: "GP_Pending_for_MTS_Approval", width: "120px" },
    //     { headerName: "Total", field: "GP_Total", width: "100px" },
    //   ],
    // },
  ];
  const exportToExcel = () => {
    // Format data for export
    const exportData = marketing.map((row, index) => ({
      "Sl No.": index + 1,
      "Product Code": row.product_code,
      Product: row.product_name,
      Unit: row.unit,

      // DLR Headers
      "DLR Stock": row.DLR_stock,
      "DLR MTS Pending": row.DLR_Pending_for_MTS_Approval,
      "DLR Stock - MTS": row.DLR_Total,

      // Govt Headers
      "Govt Stock": row.Govt_stock,
      "Govt MTS Pending": row.Govt_Pending_for_MTS_Approval,
      "Govt Stock - MTS": row.Govt_Total,

      // OTGT Headers
      "OTGT Stock": row.OTGT_stock,
      "OTGT MTS Pending": row.OTGT_Pending_for_MTS_Approval,
      "OTGT Stock - MTS": row.OTGT_Total,
      // "Net Demand Value(INR)": row.demand_price,
      // GP: row.gp, // Uncomment if GP is required
    }));

    // Create a new workbook and add a worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(exportData);

    // Add worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Pre-Approved Stock");

    // Trigger the download
    XLSX.writeFile(workbook, "Pre-approved_stock.xlsx");
  };
  const breadcrumbs = [
    <Link underline="hover" key="1" color="#FEFFFF" href="/dashboards/default">
      <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" color="#FEFFFF" />
    </Link>,

    <Typography sx={{ fontSize: "13px", fontWeight: 800 }} key="3" color="#FEFFFF">
      Pre-Approved Stock{" "}
    </Typography>,
  ];
  const buttonArray = [
    "Asavarishtams",
    "Thylam",
    "Lehyam",
    "Gritham",
    "Rasakriya",
    "KC",
    "KSC",
    "Choornam",
    "Liquid Kashayam",
    "Patent Items",
    "Gulika",
    "Bhasmam",
    "Siddha",
  ];
  return (
    <>
      <DefaultNavbar
        routes={page_Routes}
        action={{
          type: "external",
          route: "https://creative-tim.com/product/soft-ui-dashboard-pro-react",
          label: "buy now",
        }}
        // transparent
        dark
      />
      <SoftBox sx={{ marginTop: "65px" }} py={1}>
        <Grid container>
          <Grid md={9.5}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  height: "40px",
                  width: "100%",
                  alignContent: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ height: "30px" }}>
                  <CustomSeparator breadcrumbs={breadcrumbs} />
                </Box>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignContent: "center",
                    marginRight: "40px",
                    color: "white",
                    // backgroundColor:""
                  }}
                >
                  <UploadFileRoundedIcon
                    sx={{
                      cursor: "pointer", // Change cursor to pointer on hover
                      color: "#1D81F6", // Default color
                      fontSize: "1.5rem !important", // Increase the size of the icon
                      transition: "0.3s",
                      mx: 3, // Smooth transition for hover effects
                      "&:hover": {
                        color: "#4DC3F8", // Change color on hover
                        fontSize: "2.5rem", // Increase icon size on hover
                      },
                    }}
                    onClick={exportToExcel}
                  />
                  <Chip
                    size="small"
                    label={`Demand updated on: ${sales_order_last_updated}`}
                    color="success"
                    sx={{ bgcolor: "#439322", color: "white !important" }}
                  />
                  <Chip
                    size="small"
                    label={`Invoice  updated on: ${sales_invoice_last_updated}`}
                    color="success"
                    sx={{ marginLeft: "10px", bgcolor: "#439322", color: "white !important" }}
                  />
                </div>
              </div>
              <div className="ag-theme-alpine" style={{ height: "77vh", width: "94%" }}>
                <AgGridReact
                  paginationPageSize={20}
                  rowData={marketing}
                  columnDefs={columnDefs}
                  pagination={true}
                />
              </div>
            </div>
          </Grid>
          <Grid md={2.5}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyConntent: "center",
                alignItem: "flexEnd",
                alignContent: "flexEnd",
                gap: "10px",
                paddingInline: "50px",
              }}
              py={5}
              mb={1}
            >
              {buttonArray.map((label, index) => (
                <SoftButton
                  onClick={() => {
                    label === category ? setCategory("all") : setCategory(label);
                  }}
                  key={index}
                  variant="gradient"
                  color={label === category ? "success" : "info"}
                >
                  {label}
                </SoftButton>
              ))}
            </Box>
          </Grid>
        </Grid>
      </SoftBox>
    </>
  );
}

export default DashboardMarketing;
